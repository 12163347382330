.main-view {
    //background-color: #003e29;
}
.landing-image {
    background-color: #003e29;
}
.app-view {
    //background-color: #003e29;
    background: rgb(255,255,255);
    //background: linear-gradient(180deg, rgba(255, 255, 255, 0) 60%, rgba(0, 62, 41, .6) 100%)
}
.home-view {
    background: rgb(255,255,255);
    //background: linear-gradient(180deg, rgba(255, 255, 255, 0) 60%, rgba(0, 62, 41, .6) 100%)
}
.MuiGrid-root .copyright {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 2px 0px inset;
}
.body {
    //overflow: hidden;
    //height: 100%;
}
.scroll-view {
    //box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    //box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}
.router-button.MuiButton-root {
    color: black !important;
    font-weight: 300;
    font-size: 14pt;
    text-transform: none;
    border-radius: 40px;
    &:hover {
        background-color: transparent;
    }
    &:active {}
    &:focus {}
    &.active-nav {
        font-weight: 500;
    }
    padding-left: 20px;
    padding-right: 20px;
}
// main: '#003e29',
// main text: '#467061',
// other main text: #96bd97
.right {
    margin-left: auto !important;
}

.nav-drawer:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border: none;
    mask-image: linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0.5), rgba(0,0,0,0) 50%);
}

.MuiButton-root.tile-button {
    border: 0px solid black;
    height: 100%;
    width: 100%;
    max-width: 240px;
    max-height: 100px;
    font-size: 16px;
    font-weight: 350;
    padding: 22px;
    border-radius: 20px;
    margin-left: auto !important;
    margin-right: auto !important;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
    text-align: center;
}

.MuiButton-root.tile-button.appointment-button {
    background-color: #de6a26;
    color: white;
}
